<template>
  <footer>
    <div class="footer-box">
      <div class="footer-top">
        <div class="brands">
          <img v-lazy="imagePath" class="brand" :alt="alts" title="我的网站" />
          <p>用 芯 改 变 生 活</p>
        </div>
        <div class="iconlist">
          <div class="images">
            <img
              v-lazy="require('@/assets/footer/wb.png')"
              alt=""
              class="image"
            />
            <span class="image_none">
              <img
                src="@/assets/footer/qrCode.png"
                :alt="alts"
                class="image_two"
                title="我的网站"
              />
            </span>
          </div>
          <div class="images">
            <img
              v-lazy="require('@/assets/footer/dy.png')"
              alt=""
              class="image"
            />
            <span class="image_none">
              <img
                src="@/assets/footer/qrCodetwo.png"
                :alt="alts"
                class="image_two"
                title="我的网站"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-left">
          <div>
            <!-- 上 -->
            <div class="footer-menu">
              <div
                :class="{ active: activeNav === '/dashboard' }"
                @click="navigators('/dashboard')"
              >
                微芯首页
              </div>
              <p style="width: 1.875vw"></p>
              <div
                class="menu-product"
                :class="{ active: activeNav === '/product' }"
                @click="navigators('/product')"
              >
                产品中心
              </div>
              <p style="width: 1.875vw"></p>
              <div
                class="menu-Inquiry"
                :class="{ active: activeNav === '/solution' }"
                @click="navigators('/solution')"
              >
                解决方案
              </div>
              <p style="width: 1.875vw"></p>
              <div
                class="menu-Inquiry"
                :class="{ active: activeNav === '/technicalSupports' }"
                @click="navigators('/technicalSupports')"
              >
                技术文献
              </div>
              <p style="width: 1.875vw"></p>
              <div
                class="menu-Inquiry"
                :class="{ active: activeNav === '/news' }"
                @click="navigators('/news')"
              >
                相关资讯
              </div>
              <p style="width: 1.875vw"></p>

              <div
                :class="{ active: activeNav === '/about' }"
                @click="navigators('/about')"
              >
                关于我们
              </div>
            </div>
            <!-- 下 -->
            <div class="footer-contact">
              <div class="">珠海微芯科技有限公司</div>
              <div class="phone">
                <img src="@/assets/footer/phone-call.png" alt="" />
                <!--
                联系电话：0756-8854123
                -->
                 联系电话：13318956934
              </div>
              <div class="">
                <img
                  src="@/assets/footer/map-pin.png"
                  alt=""
                />地址：珠海市金湾区联港工业区永安三路
              </div>
            </div>
          </div>
          <div class="footer-messages">
            <div class="messages">
              <div class="message">
                <img
                  v-lazy="require('@/assets/footer/officialAccount.png')"
                  :alt="alts"
                  class="qr-code"
                  title="我的网站"
                />
              </div>
              <div class="message">
                <img
                  v-lazy="require('@/assets/footer/smallRoutine.png')"
                  :alt="alts"
                  class="qr-code"
                  title="我的网站"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <div class="link"></div>
          <div class="particulars">
            <div class="copyright">版权所有：珠海微芯科技有限公司</div>
            <div class="filings">粤ICP备2022145392号-1</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footerIndex",
  mounted() {
    window.addEventListener("scroll", this.scrolling);
    this.activeNav = this.routers;
  },
  data() {
    return {
      activeNav: "/dashboard",
      show: false,
      imagePath: require("@/assets/footer/100商标 4@2x.png"),
      alts: "智能一体式直流充电桩",
    };
  },
  methods: {
    navigators(index) {
      if (index === this.activeNav) return false;
      this.$router.push(index);
    },
  },
  computed: {
    routers() {
      return this.$route.path;
    },
  },
  watch: {
    routers(val) {
      this.activeNav = val;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1200px) {
  .footer-box{
    width: 80%!important;
  }
  .footer-box .footer-bottom .footer-right .link {
    display: none !important;
  }
  .footer-box .footer-bottom .footer-right .particulars {
    position: absolute;
    left: 0;
  }
}
@media screen and (max-width: 600px) {
  .footer-box{
    width: 90%!important;
  }
  .footer-box .footer-bottom .footer-contact div {
    line-height: 2vw !important;
    margin-bottom: 1vw !important;
  }
  .footer-box .footer-bottom .footer-right .particulars {
    display: flex;
    justify-content: space-between;
  }
}
footer {
  width: 100%;
  height: 23.542vw;
  background-color: #0e3763;
  color: #fff;
  padding: 1.875vw 0;
  box-sizing: border-box;
}
footer .footer-box {
  width: 62.5vw;
  height: 100%;
  margin: 0 auto;
}
footer .footer-box .footer-top {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
footer .footer-box .footer-top .brands {
  display: flex;
  justify-content: space-between;
  line-height: 2.083vw;
}
.footer-box .footer-top .brands .brand {
  width: 9.063vw;
  height: 2.083vw;
  margin-right: 0.833vw;
}
footer .footer-box .footer-top .brands p {
  font-size: 0.833vw;
  color: #fff;
}
footer .footer-box .footer-top .iconlist {
  width: 7.5vw;
  height: 2.917vw;
  display: flex;
  justify-content: space-between;
  cursor: pointer; // 鼠标显示小手
}
.footer-box .footer-top .iconlist .image {
  width: 2.917vw;
  height: 2.917vw;
}
.footer-box .footer-top .iconlist .image_two {
  width: 6.667vw;
  height: 6.667vw;
  background-color: #fff;
}
.footer-box .footer-top .iconlist .images {
  width: 2.5vw;
  height: 2.5vw;
  position: relative;
  z-index: 10;
}
.footer-top .iconlist .images .image_none {
  display: none;
  box-sizing: border-box;
  width: 7.292vw;
  height: 7.292vw;
  background-color: #ddd;
  position: absolute;
  bottom: 3.125vw;
  left: -88%;
  padding: 0.313vw;
}
.footer-top .iconlist .images .image_none::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -8%;
  transform: translateX(-50%);
  border-top: 0.6vw solid #ddd;
  border-left: 0.4vw solid transparent;
  border-right: 0.4vw solid transparent;
}
.footer-top .iconlist .images:hover .image_none {
  display: block;
}
.footer-bottom .footer-menu {
  height: 1.458vw;
  display: flex;
  margin-top: 1.875vw;
  margin-bottom: 1.875vw;
  color: #fff;
  font-size: 0.833vw;
  line-height: 1.458vw;
}
.footer-box .footer-bottom .footer-menu div {
  cursor: pointer; // 鼠标显示小手
}
.footer-box .footer-bottom .footer-contact {
  margin-bottom: 1.875vw;
}
.footer-box .footer-bottom .footer-contact div {
  font-size: 0.833vw;
  margin-bottom: 0.417vw;
  line-height: 1.458vw;
}

footer .footer-box .footer-bottom .footer-right {
  display: flex;
  position: relative;
  line-height: 1.458vw;
}
.footer-box .footer-bottom .footer-right .link {
  position: absolute;
  top: 50%;
  left: 0;
  width: 34.063vw;
  height: 0.052vw;
  background-color: #d9d9d9;
  transform: translateY(-50%);
}
.footer-box .footer-bottom .footer-right .particulars {
  position: absolute;
  right: 0;
  display: flex;
  font-size: 0.833vw;
}
.footer-bottom .footer-right .particulars .copyright {
  margin-right: 1.875vw;
}
.footer-bottom .footer-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-bottom .footer-left .footer-messages {
  width: 17.083vw;
  height: 7.292vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.footer-bottom .footer-left .footer-messages .messages {
  width: 16.667vw;
  display: flex;
  justify-content: space-between;
}
.footer-left .footer-messages .messages .message {
  width: 6.875vw;
  height: 6.875vw;
  border-radius: 0.625vw;
  border: 1px solid #ffffff;
  position: relative;
}
.footer-messages .messages .message .qr-code {
  border-radius: 0.625vw;
  width: 6.875vw;
  height: 6.875vw;
  position: absolute;
  left: -0.417vw;
  top: -0.417vw;
  background-color: skyblue;
}
.footer-left .footer-contact img {
  width: 0.833vw;
  height: 0.833vw;
  vertical-align: middle;
  margin-right: 0.6vw;
}
// .jss3 {
//   background-color: #000;
// }
// .jss2 {
//   margin: 0 auto;
//   max-width: 90%;
// }
// .jss435 {
//   height: auto;
//   padding: 2.5vw 1.667vw;
// }
// @media screen and (max-width: 750px) {
//   .jss435 {
//     padding: 0 0;
//   }
// }
// .jss437 {
//   width: 90%;
//   margin: 0 4.167vw;
//   padding-bottom: 1.667vw;
// }
// .jss438 {
//   opacity: 0.1;
//   background: #ffffff;
// }
// .jss436 {
//   display: flex;
// }
// .MuiGrid-container {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   box-sizing: border-box;
// }
// @media screen and (min-width: 600px) {
//   .MuiGrid-grid-sm-10 {
//     flex-grow: 0;
//     max-width: 60%;
//     flex-basis: 60%;
//   }
// }
// .MuiGrid-item {
//   margin: 0;
//   box-sizing: border-box;
// }
// .jss454 {
//   margin: 0rem 2.5vw;
// }
// @media screen and (max-width: 750px) {
//   .jss454 {
//     margin: 0rem 0rem;
//   }
// }
// .jss455 {
//   display: flex;
//   min-width: 12.5vw;
//   align-items: start;
//   align-content: space-between;
//   flex-direction: row;
// }
// .MuiGrid-container {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   box-sizing: border-box;
// }
// .MuiGrid-grid-md-3 {
//   flex-grow: 0;
//   max-width: 25%;
//   flex-basis: 25%;
// }
// @media screen and (max-width: 750px) {
//   .MuiGrid-grid-md-3 {
//     flex-grow: 0;
//     max-width: 40%;
//     flex-basis: 40%;
//   }
// }
// .jss456 {
//   color: #ffffff;
//   margin: 1.25vw 1.667vw;
//   display: flex;
//   padding: 0rem 0rem;
//   flex-direction: column;
// }
// .jss457 {
//   font-size: 1.042vw;
//   text-align: left;
//   font-weight: 300;
//   margin-bottom: 1.8rem;
// }
// @media screen and (max-width: 750px) {
//   .jss457 {
//     font-size: 1rem;
//     text-align: left;
//     font-weight: 300;
//     margin-bottom: 1.8rem;
//   }
// }
// .jss456 span {
//   cursor: pointer;
//   margin: 0 0.208vw;
//   font-size: 0.833vw;
//   min-height: 2.292vw;
//   text-align: left;
//   font-weight: 400;
//   line-height: 1.146vw;
// }

// .jss458 {
//   margin: 0 0.208vw;
//   font-size: 0.833vw;
//   min-height: 2.292vw;
//   text-align: left;
//   font-weight: 400;
//   line-height: 1.146vw;
// }
// @media screen and (max-width: 750px) {
//   .jss458 {
//     font-size: 0.75rem;
//     text-align: left;
//     line-height: 1.25rem;
//   }
// }

// .MuiGrid-grid-md-9 {
//   flex-grow: 0;
//   max-width: 75%;
//   flex-basis: 75%;
// }
// @media screen and (max-width: 750px) {
//   .MuiGrid-grid-md-9 {
//     flex-grow: 0;
//     max-width: 60%;
//     flex-basis: 60%;
//   }
// }
// .MuiGrid-grid-sm-2 {
//   flex-grow: 0;
//   max-width: 20%;
//   flex-basis: 20%;
// }
// @media screen and (max-width: 750px) {
//   .MuiGrid-grid-sm-2 {
//     flex-grow: 0;
//     max-width: 50%;
//     flex-basis: 50%;
//   }
// }
// .jss74 {
//   width: 9.042vw;
//   margin: 0.417vw;
// }
// @media screen and (max-width: 750px) {
//   .jss74 {
//     width: 4rem;
//     margin: 0.5rem;
//     // margin-left: 3rem;
//   }
// }
// .jss441 {
//   color: #fff;
//   margin: 0 4.167vw;
//   display: flex;
//   font-size: 0.729vw;
//   font-weight: 300;
//   padding-top: 0.833vw;
// }
// @media screen and (max-width: 750px) {
//   .jss441 {
//     color: #fff;
//     margin: 0 1rem;
//     display: flex;
//     font-size: 0.575rem;
//     font-weight: 300;
//     padding-bottom: 1rem;
//   }
// }
// .MuiGrid-justify-content-xs-space-between {
//   justify-content: space-between;
// }
// .MuiGrid-container {
//   width: 100%;
//   display: flex;

//   flex-wrap: wrap;
//   box-sizing: border-box;
// }
// .jss443 {
//   margin-top: 1.167vw;
//   text-align: center;
// }
// @media screen and (max-width: 750px) {
//   .jss443 {
//     margin-top: 0.5rem;
//     text-align: center;
//   }
// }

// 选中页面时,导航栏选中样式
.active {
  color: #00589c;
  // border-bottom: 0.133vw solid #00589c;
  // font-weight: bold;
}
</style>
