import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
import Layout from "@/layout";

// 设置hidden属性true则不显示在顶部导航栏
// title属性定义底部导航栏名字
const constantRoutes = [
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    title: "微芯首页",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/index"),
      },
    ],
  },
];
import about from "./modules/about";
import news from "./modules/news";
import product from "./modules/product";
import product_detail from "./modules/product_detail";
import solution from "./modules/solution";//解决方案
import technicalSupports from "./modules/technicalSupports";//技术支持
import technicalSupport from "./modules/technicalSupport";//技术支持详情
const router = new Router({
  routes: [...constantRoutes, about, news, product, product_detail,solution,technicalSupport,technicalSupports],
});

//页面跳转滚动条归零
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
